/* eslint max-lines: 0 */
import * as _ from 'lodash';
import * as moment from 'moment';
import parsePhoneNumberFromString, { CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import {
  isArray,
  isDate,
  isEmail,
  isInt,
  isNumber,
  isNumberString,
  isObject,
  isPhoneNumber,
  isString
} from 'class-validator';
import * as xss from 'xss';

import { ISO3166FilteredCountries } from '../country';
import { defaultPagination, Pagination } from '../pagination';
import { NumberFormat } from 'libphonenumber-js/types';

export * as json from './json';

export const DEFAULT_PHONE_COUNTRY = 'US';

// See: https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_chunk
export function chunk(input: any[], size: number): any[] {
  return input.reduce((arr, item, idx) => {
    return idx % size === 0 ? [...arr, [item]] : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
  }, []);
}

// See: https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_sortby-and-_orderby
export function sortBy(array: any[], key: string): any[] {
  if (!array) {
    return array;
  }
  if (!Array.isArray(array)) {
    return array;
  }
  if (array.length === 0) {
    return array;
  }

  const compare = (k: string) => {
    return (a: Array<any>, b: Array<any>) => (a[k] > b[k] ? 1 : b[k] > a[k] ? -1 : 0);
  };

  return array.concat().sort(compare(key));
}

export function sortByCaseInsensitive(array: any[], key: string): any[] {
  if (!array) {
    return array;
  }
  if (!Array.isArray(array)) {
    return array;
  }
  if (array.length === 0) {
    return array;
  }
  const compare = (k: string) => (a: any, b: any) => {
    const compareA = typeof a[k] === 'string' ? a[k].toLowerCase() : a[k];
    const compareB = typeof b[k] === 'string' ? b[k].toLowerCase() : b[k];
    return compareA > compareB ? 1 : compareB > compareA ? -1 : 0;
  };
  return array.concat().sort(compare(key));
}

// Assumes the input array is SORTED. See: https://www.gavsblog.com/blog/find-closest-number-in-array-javascript
export function closestValueInList(array: number[], needle: number): number {
  return array.reduce((a, b) => {
    return Math.abs(b - needle) < Math.abs(a - needle) ? b : a;
  }, 0);
}

// See: https://stackoverflow.com/questions/5667888/counting-the-occurrences-frequency-of-array-elements
export function countBy(array: any[]): number {
  return array.reduce((acc, curr) => {
    // eslint-disable-next-line no-unused-expressions
    acc[curr] ? acc[curr]++ : (acc[curr] = 1);
    return acc;
  }, {});
}

// See: https://stackoverflow.com/questions/40801349/converting-lodash-uniqby-to-native-javascript
export function uniqBy(array: any[], predicate: ((x: any) => any) | string): any[] {
  if (typeof predicate === 'string' && !array.some(k => Object.keys(k).includes(predicate))) {
    return array;
  }
  const cb = typeof predicate === 'function' ? predicate : x => x[predicate];
  const map = new Map();
  for (const item of array) {
    const key = item === null || typeof item === 'undefined' ? item : cb(item);

    if (!map.has(key)) {
      map.set(key, item);
    }
  }
  return [...map.values()];
}

export function randomPick(array: any[]): any {
  return array && array[Math.floor(Math.random() * array.length)];
}

export function toCamelCase(str: string): string {
  return str
    .toLowerCase()
    .replace(/^\w|[A-Z]|\b\w/gu, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/gu, '');
}

export function breakWordsAtCaps(str: string): string {
  return str
    .replace(/(?<caps>[A-Z])/gu, ' $1')
    .replace(/\s+/gu, ' ')
    .trim();
}

export function upperFirst(str: string): string {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

export function pluck(array: any[], key: string | number): any[] {
  return array.map(obj => {
    return obj[key];
  });
}

export function hasSameKeys(obj1, obj2): boolean {
  if (!(obj1 instanceof Object && obj2 instanceof Object)) {
    throw new Error('Core: hasSameKeys(): arguments must be Object-like');
  }
  const aKeys = Object.keys(obj1).sort();
  const bKeys = Object.keys(obj2).sort();
  return JSON.stringify(aKeys) === JSON.stringify(bKeys);
}

export function objectFilter(obj, predicate): Record<any, any> {
  // Be careful with the predicate, it can filter 0 out
  return (
    Object.keys(obj)
      .filter(key => predicate(key))
      // eslint-disable-next-line no-sequences
      .reduce((res, key) => ((res[key] = obj[key]), res), {})
  );
}

// CAUTION! This is not a pure function - it mutates the
// input argument "in-place".
export function deleteFields(obj: any, badFields: string[]): void {
  // Recurse into sub-object fields if they are arrays or of type "object"
  Object.keys(obj).forEach(field => {
    if (obj[field] && (Array.isArray(obj[field]) || typeof obj[field] === 'object')) {
      deleteFields(obj[field], badFields);
    }
  });

  badFields.forEach(field => {
    delete obj[field];
  });
}

// CAUTION! This is not a pure function - it mutates the
// input argument "in-place".
export function deleteSensitiveFields(obj: any): void {
  deleteFields(obj, ['password', 'ssn']);
}

// CAUTION! This has some drawbacks - see discussion
// https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript
export function deepClone(obj): any {
  if (obj instanceof Date) {
    return new Date(obj.getTime());
  }
  if (obj instanceof moment) {
    return moment(obj);
  }
  return JSON.parse(JSON.stringify(obj));
}

export function removeNullFieldsRecursive(obj): Record<any, any> {
  if (!(obj instanceof Object)) {
    return obj;
  }
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v !== null)
      .map(([k, v]) => [k, v === Object(v) ? removeNullFieldsRecursive(v) : v])
  );
}

export function compareDateStrings(date1: string | Date, date2: string | Date): number {
  return new Date(date1).getTime() - new Date(date2).getTime();
}

// CAUTION: This is EXPERIMENTAL!
// Checks to see if the field exists anywhere in the object, at any nested level.
export function hasNestedField(obj: any, field: string): boolean {
  return obj && JSON.stringify(obj).includes(`"${field}":`);
}

export function isValidInputUrl(value: string): boolean {
  const validProtocols = ['http:', 'https:'];

  let url;
  try {
    url = new URL(value);
  } catch (_) {
    return false;
  }

  // No quotes allowed, even encoded
  if (value.match(/"/gu) || value.match(/%22/gu)) {
    return false;
  }

  return validProtocols.includes(url.protocol);
}

/**
 * @deprecated Use chronon's formatDateTime
 * */
export function getFormattedTime(timestamp: string, format: string): string {
  try {
    const date = isString(timestamp) ? new Date(timestamp) : timestamp;
    return moment(date).format(format);
  } catch (_) {
    return `Core: getFormattedTime(): Invalid date ${timestamp} or format ${format}`;
  }
}

export function removeArrayItem(array: any[], item: any): void {
  const idx = array.indexOf(item);
  if (idx > -1) {
    array.splice(idx, 1);
  }
}

export function sumArray(array: any[], field: string | null, start = 0): number {
  return array.reduce((prev, cur) => prev + (parseFloat(field ? cur[field] : cur) || 0), start);
}

export function isEmptyObject(value: any): boolean {
  return (
    value && Object.keys(value).length === 0 && Object.getPrototypeOf(value) === Object.prototype
  );
}

export function isTrulyEmpty(value) {
  // eslint-disable-next-line no-undefined
  if (value === null || value === undefined) {
    return true;
  }
  if (typeof value === 'string' && value.trim() === '') {
    return true;
  }
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }
  return typeof value === 'object' && isEmptyObject(value);
}

export function formatMinutesToHuman(minutes: number | string): string {
  minutes = typeof minutes === 'number' ? minutes : parseInt(minutes, 10);
  minutes = isNaN(minutes) ? 0 : minutes;
  const hours = minutes / 60;
  const rhours = Math.floor(hours);
  const m = Math.floor((hours - rhours) * 60);
  const hourLabel = `hour${rhours === 1 ? '' : 's'}`;

  if (m === 0) {
    return `${rhours} ${hourLabel}`;
  } else if (rhours === 0) {
    return `${m} minutes`;
  }

  return `${rhours} ${hourLabel} and ${m} minutes`;
}

export function ucWords(str: string) {
  return str
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
}

export function camelCaseToHumanReadable(str: string) {
  if (str) {
    return String(str)
      .match(/^[a-z]+|[A-Z][a-z]*/gu)
      ?.map(x => x[0].toUpperCase() + x.substring(1).toLowerCase())
      .join(' ');
  }
}

export function naturalSort(items, sortDesc = false, objKey = null) {
  const collator = new Intl.Collator('en', {
    numeric: true,
    sensitivity: 'base'
  });
  const sorted = items.sort((a, b) => {
    return collator.compare(objKey ? a[objKey] : a, objKey ? b[objKey] : b);
  });

  if (sortDesc) {
    sorted.reverse();
  }

  return sorted;
}

export function removeNulls(array: any[]): any[] {
  // eslint-disable-next-line no-undefined
  return array.filter(x => x !== null && x !== undefined && x !== '');
}

export function randomString(length: number): string {
  if (isNumberString(length)) {
    length = Number(length);
  }
  if (!isNumber(length)) {
    length = 6;
  }
  const randomBytes = require('randombytes');
  return randomBytes(length).toString('hex').slice(0, length);
}

export function randomStringWithDigitsAndSymbols(length: number): string {
  const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';
  const symbols = '!@#$%^&*()_+-=';

  const allChars = lowerCaseLetters + upperCaseLetters + numbers + symbols;

  let randomString = '';

  // Ensure that at least one lowercase, one uppercase, one digit and one symbol is generated
  randomString += lowerCaseLetters.charAt(Math.floor(Math.random() * lowerCaseLetters.length));
  randomString += upperCaseLetters.charAt(Math.floor(Math.random() * upperCaseLetters.length));
  randomString += numbers.charAt(Math.floor(Math.random() * numbers.length));
  randomString += symbols.charAt(Math.floor(Math.random() * symbols.length));

  // Fill the rest with random chars
  for (let i = 4; i < length; i++) {
    const randomNumber = Math.floor(Math.random() * allChars.length);
    randomString += allChars.substring(randomNumber, randomNumber + 1);
  }

  return randomString;
}

export function randomPassword(): string {
  return randomStringWithDigitsAndSymbols(32);
}

export function capitalize(string: string): string {
  if (isString(string)) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  return string;
}

export function setNullsToEmptyString(data: any) {
  const data2 = data;

  for (const key in data) {
    if (!data[key]) {
      data2[key] = '';
    }
  }

  return data2;
}

export function isPhoneValid(phoneNumber: string | number): boolean {
  const value = isInt(phoneNumber) ? String(phoneNumber) : phoneNumber;
  if (!isString(value)) {
    return false;
  }
  return value.startsWith('+') ? isPhoneNumber(value) : isPhoneNumber(`+1${value}`);
}

export function formatPhoneNumber(
  value: string,
  countryCode: CountryCode = DEFAULT_PHONE_COUNTRY
): string {
  return isString(value)
    ? parsePhoneNumberFromString(value, countryCode)?.formatInternational() || value
    : value;
}

export function formatPhoneNumberAsE164(
  value: string,
  countryCode: CountryCode = DEFAULT_PHONE_COUNTRY
) {
  // E.164 is a standard recommended by the International Telecommunication Union.
  // This format ensures that each PSTN (Public Switched Telephone Network) device
  // has a distinct and unique number worldwide.
  return isString(value)
    ? parsePhoneNumberFromString(value, countryCode)?.format('E.164') || value
    : value;
}

// Merges (as opposed to concatenating) 2 arrays: duplicates are removed
export function mergeArrays(arr1: any[], arr2: any[]): any[] {
  return [...new Set([...arr1, ...arr2])];
}

export function isValidDate(date: any) {
  return (
    (date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date)) ||
    (typeof date === 'string' && moment(date, moment.ISO_8601, true).isValid())
  );
}

// TODO: Make this recursive!
// CAUTION: Mutates the input object!!
export function ensureDateTypes(obj: object): void {
  if (!isObject(obj)) {
    return;
  }
  const keys = Object.getOwnPropertyNames(obj);

  keys.forEach(key => {
    if (isValidDate(obj[key])) {
      obj[key] = new Date(obj[key]);
    }
  });
}

export function sanitizeInput(str: string): string {
  const xssFilter = new xss.FilterXSS({
    whiteList: {
      div: [],
      a: ['href', 'target'],
      p: [],
      b: [],
      i: [],
      u: [],
      br: [],
      strong: []
    },
    stripIgnoreTag: true,
    stripIgnoreTagBody: false
  });
  return xssFilter.process(str).replace('[removed]', '').replace(/  +/gu, ' ').trim();
}

export function recursivelySanitizeValues(value: unknown): unknown {
  if (Array.isArray(value)) {
    return value.map(recursivelySanitizeValues);
  }
  if (isObject(value) && !isDate(value)) {
    return Object.fromEntries(
      Object.entries(value).map(([key, value]) => [key, recursivelySanitizeValues(value)])
    );
  }
  if (isString(value)) {
    value = sanitizeInput(value);
  }

  return value;
}

export function sortObjectByKeys(obj) {
  return obj
    ? Object.keys(obj)
        .sort()
        .reduce((o, k) => {
          o[k] = obj[k];
          return o;
        }, {})
    : {};
}

export function shouldTruncateString(str: string, length = 50): boolean {
  return str?.length > length;
}

export function truncateString(str: string, length = 50, addEllipses = true): string {
  if (isString(str) || isNumber(str)) {
    const newStr = String(str);
    const shouldUseEllipses = addEllipses && shouldTruncateString(newStr, length);
    length = shouldUseEllipses ? length - 3 : length;
    return `${newStr.slice(0, length)}${shouldUseEllipses ? '...' : ''}`;
  }
  return '';
}

export function isValidInternalEmail(email: string): boolean {
  if (isEmail(email)) {
    const allowedDomains = ['loadsmart.', 'opendock.'];
    const splitEmail = email.split('@');

    if (splitEmail.length === 2) {
      const username = splitEmail[0];
      const domain = splitEmail[1];
      if (allowedDomains.find(d => domain.includes(d))) {
        if (username.includes('internal')) {
          return true;
        }
      }
    }
  }

  return false;
}

export function formatCurrency(amount: number): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return formatter.format(amount);
}

export function removeArrayDuplicates(arr: any[]): any[] {
  return [...new Set(arr)];
}

export function removeArrayDateDuplicates(arr: Date[]): Date[] {
  return [...new Set(arr.map(date => date.toString()))].map(date => new Date(date));
}

export function removeArrayObjDuplicates(arr: any[]): any[] {
  return [
    ...new Set(
      arr.map(obj => {
        return JSON.stringify(obj);
      })
    )
  ].map(obj => {
    return JSON.parse(obj);
  });
}

export function removeArrayValsFromAnother(arr: any[], arrValsToRemove: any[]): any[] {
  return isArray(arr)
    ? arr.filter(el => (isArray(arrValsToRemove) ? !arrValsToRemove.includes(el) : true))
    : [];
}

export function objectContainsKeyFromArr(obj: any, arrOfKeys: any[]): boolean {
  return isObject(obj) ? Boolean(arrOfKeys?.some(key => Object.keys(obj).includes(key))) : false;
}

// I Hate this, but there isn't a good way to determine this if we only have the URL
export function isImageUrl(fileUrl: string): boolean {
  if (!isString(fileUrl)) {
    return false;
  }

  const imageExtensions = ['jpg', 'jpeg', 'png'];
  const fileExtension = fileUrl.split('.').pop();
  return imageExtensions.includes(fileExtension);
}

export function getObjectsKeyedById(objArr: { [key: string]: any }[]) {
  const objectsKeyedById = {};
  objArr.forEach(obj => {
    objectsKeyedById[obj.id] = obj;
  });
  return objectsKeyedById;
}

export function getSetDiff(setA: Set<unknown>, setB: Set<unknown>): Set<unknown> {
  return new Set([...setA].filter(element => !setB.has(element)));
}

export declare enum HttpStatus {
  CONTINUE = 100,
  SWITCHING_PROTOCOLS = 101,
  PROCESSING = 102,
  EARLYHINTS = 103,
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NON_AUTHORITATIVE_INFORMATION = 203,
  NO_CONTENT = 204,
  RESET_CONTENT = 205,
  PARTIAL_CONTENT = 206,
  AMBIGUOUS = 300,
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  SEE_OTHER = 303,
  NOT_MODIFIED = 304,
  TEMPORARY_REDIRECT = 307,
  PERMANENT_REDIRECT = 308,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  NOT_ACCEPTABLE = 406,
  PROXY_AUTHENTICATION_REQUIRED = 407,
  REQUEST_TIMEOUT = 408,
  CONFLICT = 409,
  GONE = 410,
  LENGTH_REQUIRED = 411,
  PRECONDITION_FAILED = 412,
  PAYLOAD_TOO_LARGE = 413,
  URI_TOO_LONG = 414,
  UNSUPPORTED_MEDIA_TYPE = 415,
  REQUESTED_RANGE_NOT_SATISFIABLE = 416,
  EXPECTATION_FAILED = 417,
  I_AM_A_TEAPOT = 418,
  MISDIRECTED = 421,
  UNPROCESSABLE_ENTITY = 422,
  FAILED_DEPENDENCY = 424,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  HTTP_VERSION_NOT_SUPPORTED = 505
}

export const objPropExists = (object, key) => {
  return Object.prototype.hasOwnProperty.call(object, key);
};

export const objExistsInArr = (arr, obj) => {
  const objString = JSON.stringify(obj);
  return arr.find(item => JSON.stringify(item) === objString);
};

export function leftPadString(
  input: string | number,
  charactersToPad = 1,
  padCharacter = '0',
  maxCharacters = null
) {
  const str = input.toString();
  maxCharacters = maxCharacters ?? str.length + charactersToPad;
  return str.padStart(maxCharacters, padCharacter);
}

export function roundToNearestNumber(num: number, multiple: number, roundUp = true): number {
  if (roundUp) {
    return Math.ceil(num / multiple) * multiple;
  }
  return Math.floor(num / multiple) * multiple;
}

export function toKebabCase(str: string): string {
  if (!str) {
    return str;
  }
  return str
    .replace(/\s+/gu, '-') // Replace whitespace with dashes
    .replace(/:/gu, '') // Remove colons
    .replace(/,/gu, '') // Remove commas
    .replace(/(?<lowercase>[a-z])(?<uppercase>[A-Z])/gu, '$1-$2') // Convert camelCase to kebab-case
    .toLowerCase(); // Convert to lowercase
}

export function snakeToTitleCase(str: string): string {
  return (
    str
      // eslint-disable-next-line require-unicode-regexp
      .replace(/^[-_]*(?<temp1>.)/, (_, c) => c.toUpperCase())
      // eslint-disable-next-line require-unicode-regexp
      .replace(/[-_]+(?<temp1>.)/g, (_, c) => ` ${c.toUpperCase()}`)
  );
}

export function tryParseJson(jsonString: string): any | null {
  if (jsonString && typeof jsonString === 'object') {
    return jsonString;
  }

  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return null;
  }
}

export function deepEqual(a: any, b: any): boolean {
  if (a === b) {
    return true;
  }

  if (a instanceof Date && b instanceof Date && a.getTime() !== b.getTime()) {
    // If the values are Date, compare them as timestamps
    return false;
  }

  if (a !== Object(a) || b !== Object(b)) {
    // If the values aren't objects, they were already checked for equality
    return false;
  }

  const props = Object.keys(a);

  if (props.length !== Object.keys(b).length) {
    // Different number of props, don't bother to check
    return false;
  }

  return props.every(p => deepEqual(a[p], b[p]));
}

export function getCountryCallingCodes() {
  const supportedCountries = Object.values(ISO3166FilteredCountries).map(country => {
    return {
      iso2: country.iso2,
      name: country.name
    };
  });
  const supportedCountryCodes = Object.values(supportedCountries).map(country => country.iso2);
  return supportedCountries.map(country => {
    return {
      ...country,
      callingCode: getCountryCallingCode(country.iso2 as CountryCode)
    };
  });
}

export function removeNonAlphaNumericChars(str: string): string {
  return str.replace(/[^a-z0-9]/giu, '');
}

export function insertStringIntoString(
  str: string,
  strToInsert: string,
  insertionIndex: number
): string {
  return `${str.slice(0, insertionIndex)}${strToInsert}${str.slice(insertionIndex)}`;
}

export function pluralize(count: number, word: string, plural?: string) {
  plural = plural ?? `${word}s`;
  return Math.abs(count) === 1 ? word : plural;
}

export function recursivelyRemoveKeys(obj, keyToRemove) {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        recursivelyRemoveKeys(obj[key], keyToRemove);
      }

      if (key === keyToRemove) {
        delete obj[key];
      }
    }
  }
  return obj;
}

type PaginateArrayResult<T = any> = {
  data: T[];
  total: number;
  count: number;
  page: number;
  pageCount: number;
};

export function paginateArray<T = any>(
  arr: T[],
  opts?: Partial<Pagination>
): PaginateArrayResult<T> {
  if (!isArray(arr)) {
    throw new Error(`Core: paginateArray(): expected array and got ${typeof arr}`);
  }

  const size = Math.min(opts?.size ?? defaultPagination.size, defaultPagination.size);
  const total = arr.length;
  const pageCount = Math.ceil(total / size);
  const page = Math.min(Math.max(opts?.page ?? defaultPagination.page, 1), pageCount);
  const start = (page - 1) * size;
  const end = size + start;
  const data = arr.slice(start, end);
  const count = data.length;
  return { data, total, count, page, pageCount };
}

export function getNestedPropertyByDotNotation(obj, path) {
  return path.split('.').reduce((o, key) => (o && o[key] !== 'undefined' ? o[key] : null), obj);
}

/**
 * Joins multiple names into a single full name.
 */
export function namesToFullName(...names: Array<string | undefined>): string {
  return names
    .flatMap(s => s?.split(' ') ?? [])
    .map(s => s?.trim())
    .filter(s => (s?.length ?? 0) > 0)
    .join(' ');
}

/**
 * Transforms a string into a query object that can be used with NestJS Crud.
 */
export function searchStringToQuery(
  searchStr: string,
  {
    searchableFields = [],
    searchFieldsToSplit = []
  }: {
    searchableFields?: string[];
    searchFieldsToSplit?: string[];
  } = {}
) {
  const filters = searchStr
    ? searchableFields.reduce((acc, curr) => {
        const splitted = searchFieldsToSplit.includes(curr)
          ? [{ [curr]: { $inL: searchStr?.toLowerCase()?.split(' ') } }]
          : [];
        return [...acc, { [curr]: { $contL: searchStr } }, ...splitted];
      }, [])
    : [];
  return filters;
}

export function sortByArray(arrayToSort, sortOrder) {
  const orderMap = new Map();
  sortOrder.forEach((item, index) => orderMap.set(item, index));

  return arrayToSort.sort((a, b) => {
    const indexA = orderMap.has(a) ? orderMap.get(a) : Infinity;
    const indexB = orderMap.has(b) ? orderMap.get(b) : Infinity;
    return indexA - indexB;
  });
}
